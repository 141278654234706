import React, { useState } from "react";
import ReactQuill from "react-quill";
import LoadingOverlay from "../../../components/LoadingOverlay";
import "react-quill/dist/quill.snow.css";
import {
  createClinicalNotesCustomTemplate,
  saveClinicalNotesCustomTemplate,
} from "../api.service";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { Editor } from "../../../components";
import toast from "react-hot-toast";

const CustomTemplateForm = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [file, setFile] = useState(null);
  const [output, setOutput] = useState("");

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const resetForm = () => {
    setTitle("");
    setEditorContent("");
    setFile(null);
    setOutput("");
  };

  const handleSubmit = async () => {
    if (output) {
      // Save Template
      console.log("editorContent", output);
      try {
        setLoading(true);
        await saveClinicalNotesCustomTemplate(title, output?.markdown);
        resetForm();
        setLoading(false);
        toast.success("Successfully saved template.");
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
        setLoading(false);
      }
    } else {
      // Generate Template
      try {
        setLoading(true);
        const response = await createClinicalNotesCustomTemplate(
          title,
          editorContent,
          file
        );
        setOutput(response.data.text);
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong");
        console.error(error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="w-1/2 justify-center items-center">
      <h1 className="text-2xl font-bold mb-4">Create a Custom Template</h1>
      {loading ? (
        <div className="mt-12">
          <LoadingOverlay />
        </div>
      ) : output ? ( // Hide form if output is present
        <div>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Template Title"
            className="w-full p-2 mb-4 border border-gray-300 rounded text-2xl font-bold"
          />
          <p className="text-center mb-4">
            This is the template generated. You can modify it as needed.
          </p>
          <div className="bg-white rounded-lg">
            <Editor value={output} onChange={setOutput} className="mb-4" />
          </div>
          <button
            type="submit"
            className="w-full mt-12 mb-4 py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleSubmit}
          >
            Save Template
          </button>
          <button
            type="submit"
            className="w-full mb-12 py-2 px-4 border border-indigo-600 text-indigo-600 font-semibold rounded-md shadow-md hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => {
              setOutput("");
            }}
          >
            Start Over
          </button>
        </div>
      ) : (
        <>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Template Title"
            className="w-full p-2 mb-4 border border-gray-300 rounded text-2xl font-bold"
          />
          <div
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => document.getElementById("fileInput").click()}
            className="w-full p-4 mb-4 border-2 border-dashed border-gray-300 rounded text-center h-32 flex items-center justify-center cursor-pointer"
          >
            {file ? (
              <p>{file.name}</p>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <DocumentTextIcon className="w-16 h-16 mb-2 text-purple-600" />
                <p>Drag and drop a file here, or click to select a file</p>
              </div>
            )}
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>

          <hr className="mb-4" />
          <p className="text-center mb-4">OR</p>
          <hr className="mb-4" />

          <ReactQuill
            value={editorContent}
            onChange={setEditorContent}
            className="mb-4 h-64"
          />

          <button
            type="submit"
            className="w-full mt-12 mb-12 py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleSubmit}
          >
            {!output ? "Generate Template" : "Save Template"}
          </button>
        </>
      )}
    </div>
  );
};

export default CustomTemplateForm;
