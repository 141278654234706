import { makeAutoObservable, observable, action } from "mobx";
import { updateHistory, fetchThreadHistories } from "./api.service.v2";
import {
  runAssistant,
  createThread,
  addMessage,
  fetchMessages,
} from "./azureApiService";
import { deleteHistory } from "../../components/History";
import { PERSONAS } from "./Personas";

class NavixScribeV2Store {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  tool = {};
  activeSection = "input";
  activeRecord = "";
  outputActiveRecord = "";
  triggerSection = "";
  selectedPersona = null;

  // Timer-related states
  isRecording = false;
  recordingStartTime = 0;
  elapsedTime = 0;
  savedDuration = 0;

  threads = [];
  @observable selectedThread = null;
  @observable messages = [];
  @observable loading = false;

  // Timer actions
  startRecording = () => {
    this.isRecording = true;
    this.recordingStartTime = Date.now();
    this.updateElapsedTime();
  };

  stopRecording = () => {
    this.isRecording = false;
    this.savedDuration = this.elapsedTime;
    this.recordingStartTime = 0;
    this.elapsedTime = 0;
  };

  updateElapsedTime = () => {
    if (this.isRecording) {
      this.elapsedTime = Date.now() - this.recordingStartTime;
      setTimeout(this.updateElapsedTime, 1000);
    }
  };

  setSelectedPersona = (val) => {
    this.selectedPersona = val;
    localStorage.setItem("selectedPersona", JSON.stringify(val)); // Save to local storage
  };

  loadSelectedPersona = () => {
    const savedPersona = localStorage.getItem("selectedPersona");
    if (savedPersona) {
      this.selectedPersona = JSON.parse(savedPersona);
    }
  };

  setTool = (tool) => {
    this.tool = tool;
  };

  // Input Tool properties and methods
  output = "";
  utterances = "";
  outputType = "";
  inputActiveRecord = "";
  inputDocument = null;
  inputForm = {
    title: "",
    inputText: "",
    file: "",
    filename: "",
    audioDuration: 0,
    audioDurationString: "",
    output: "",
  };
  inputUploads = [];
  inputHistories = [];
  clinicalNotesHistories = [];
  questionHistories = [];
  summaryHistories = [];

  get inputOutput() {
    return this.inputForm.output;
  }

  setInputActiveRecord = (val) => {
    console.log("setInputActiveRecord - val:", val);
    if (val.output === null) {
      console.error("setInputActiveRecord - val.output is null", val);
    }
    this.inputActiveRecord = val;
    console.log(
      "setInputActiveRecord - this.inputActiveRecord:",
      this.inputActiveRecord
    );
  };

  setInputDocument = (val) => {
    this.inputDocument = val;
  };

  setActiveHistory = (historyId, section) => {
    this.activeRecord = historyId;
    this.activeSection = section;
    // for debugging the active history and section
    console.log(
      "setActiveHistory - History ID:",
      historyId,
      "Section:",
      section
    );
  };

  /**
   * Used for setting the histories
   * instead of using local state.
   */
  setInputHistories = (val) => {
    this.inputHistories = val;
  };

  setClinicalNotesHistories = (val) => {
    this.clinicalNotesHistories = val;
  };

  setQuestionHistories = (val) => {
    this.questionHistories = val;
  };

  setSummaryHistories = (val) => {
    this.summaryHistories = val;
  };

  setInputUploads = (val) => {
    this.inputUploads = val;
  };

  setOutput = (val, utterances, type) => {
    this.output = val;
    this.utterances = utterances;
    this.outputType = type;
  };

  setInputForm = (name, value) => {
    this.inputForm[name] = value;
  };

  setOutputActiveRecord = (history) => {
    this.outputActiveRecord = history;
  };

  setShowInputAccordion = (val) => {
    this.showInputAccordion = val;
  };

  *handleSaveFeedbackValue(value, id) {
    try {
      yield updateHistory(id, { feedbackValue: value });

      if (this.outputActiveRecord._id === id) {
        this.outputActiveRecord.feedbackValue = value;
      }

      return {
        success: true,
        message: "Successfully submitted your feedback.",
      };
    } catch (error) {
      return {
        success: false,
        message: "Something went wrong!",
      };
    }
  }

  setTriggerSection = (val) => {
    this.triggerSection = val;
  };

  clear = () => this.setTool({});

  // Thread and message management methods
  fetchThreads = async () => {
    const histories = await fetchThreadHistories();
    this.threads = histories.result.map((history) => ({
      id: history.threadId,
      title: history.title,
    }));
  };

  @action
  selectThread = async (threadId) => {
    if (this.selectedThread !== threadId) {
      this.setLoading(true);
      console.log("Loading state set to true");
      this.selectedThread = threadId;
      await this.fetchThreadMessages(threadId);
      this.setLoading(false);
      console.log("Loading state set to false");
    }
  };

  @action
  fetchThreadMessages = async (threadId) => {
    try {
      const fetchedMessages = await fetchMessages(threadId);
      this.setMessages(fetchedMessages);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      this.setMessages([]);
    }
  };

  @action
  setMessages(messages) {
    console.log("Messages being set:", messages);
    this.messages = messages;
  }

  @action
  setSelectedThread(threadId) {
    this.selectedThread = threadId;
    console.log("Selected Thread:", this.selectedThread);
  }

  async sendMessage(message) {
    if (this.selectedThread) {
      await addMessage(this.selectedThread, "user", message);
      const response = await runAssistant(
        message,
        this.selectedThread,
        "asst_HagPjnY7H8EYT5gRm8o8DSsX",
        ""
      );
      this.messages.push({ role: "user", content: message });
      this.messages.push({ role: "assistant", content: response.content });
    }
  }

  async createNewThread() {
    const threadResponse = await createThread();
    const threadId = threadResponse.thread_id;
    this.threads.push({ id: threadId, title: "New Conversation" });
    await this.selectThread(threadId);
  }

  @action
  async handleDelete(id) {
    try {
      const success = await deleteHistory(this.rootStore.store, id);
      if (success) {
        console.log("History deleted successfully");
      } else {
        console.error("Failed to delete history");
      }
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  }

  @action
  setLoading(value) {
    this.loading = value;
    console.log("Loading state updated:", value);
  }

  PERSONAS = PERSONAS;

  getPersonaPrompt() {
    if (!this.selectedPersona) return "";

    if (this.selectedPersona.persona === "Custom") {
      return this.selectedPersona.customResponse;
    }

    const selectedPersonaObj = this.PERSONAS.find(
      (p) => p.name === this.selectedPersona.persona
    );
    return selectedPersonaObj ? selectedPersonaObj.prompt : "";
  }
}

export const navixScribeV2Store = new NavixScribeV2Store();
